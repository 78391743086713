// hack to get around https://github.com/TheSGJ/nextjs-toploader/issues/56
// this should be removed and NextTopLoader should be used directly once that's fixed
'use client'
import { usePathname } from 'next/navigation'
import { useRouter } from 'next/navigation'
import NextTopLoader from 'nextjs-toploader'
import { useEffect } from 'react'
import nprogress from 'nprogress'

export const TopLoader: React.FC = () => {
  const router = useRouter()
  const pathname = usePathname()
  useEffect(() => {
    nprogress.done()
  }, [pathname, router])
  return <NextTopLoader color="#133453" height={6} />
}
